import _debounce from "lodash.debounce";

export const ScrollToTopMixin = {
  data() {
    return {
      pStart: { x: 0, y: 0 },
      pStop: { x: 0, y: 0 },
      pScrollY: window.scrollY,
    };
  },
  methods: {
    scrollToTop() {
      const el = document.getElementById("survey");
      if(el){
        el.scrollIntoView();
      }else{
        window.scrollTo(0, 0)
      }
      
      this.pScrollY = 0;
    },

    scrollListener: _debounce(async function($event) {
      const el = document.getElementById("survey-scroller");
      const scrollHeight = el.scrollHeight;
      const scrollTop = el.scrollTop;
      const innerHeight = el.clientHeight;

      if ($event.deltaY < 0 && scrollTop === 0 && this.pScrollY === 0) {
        if (this.isBackable) {
          this.onBack();
        }
      } else if (
        $event.deltaY > 0 &&
        innerHeight + scrollTop == scrollHeight &&
        (this.pScrollY === scrollTop || scrollTop === 0)
      ) {
        if (this.isLastPage) return;
        await this.onSubmit();
      }

      this.pScrollY = scrollTop;
    }, 500),

    swipeStart(e) {
      if (typeof e["targetTouches"] !== "undefined") {
        var touch = e.targetTouches[0];
        this.pStart.x = touch.screenX;
        this.pStart.y = touch.screenY;
      } else {
        this.pStart.x = e.screenX;
        this.pStart.y = e.screenY;
      }
    },
    swipeEnd: _debounce(async function(e) {
      if (typeof e["changedTouches"] !== "undefined") {
        var touch = e.changedTouches[0];
        this.pStop.x = touch.screenX;
        this.pStop.y = touch.screenY;
      } else {
        this.pStop.x = e.screenX;
        this.pStop.y = e.screenY;
      }
      await this.touchend();
    }, 500),

    touchend() {
      const el = document.getElementById("survey-scroller");
      const scrollTop = el.scrollTop;
      const innerHeight = el.clientHeight;
      const scrollHeight = el.scrollHeight;

      const changeY = this.pStart.y - this.pStop.y;
      const changeX = this.pStart.x - this.pStop.x;
      const position = Math.atan2(changeY, changeX);

      if (this.pScrollY + innerHeight >= scrollHeight && position > 0) {
        if (this.isLastPage) return;
        this.onSubmit();
      } else if (scrollTop <= 0 && this.pScrollY <= 0 && position < 0) {
        this.pScrollY = 0;
        if (!this.isBackable) return;
        this.onBack();
      } else {
        this.pScrollY = scrollTop;
      }
    },
  },
  mounted() {
    const self = this;
    document.getElementById("survey-scroller").addEventListener(
      "wheel",
      function(e) {
        if (!self.isScrollable) return;
        self.scrollListener(e);
      },
      { passive: true }
    );

    document.getElementById("survey-scroller").addEventListener(
      "touchstart",
      function(e) {
        if (!self.isScrollable) return;
        self.swipeStart(e);
      },
      { passive: true }
    );
    document.getElementById("survey-scroller").addEventListener(
      "touchend",
      function(e) {
        if (!self.isScrollable) return;
        self.swipeEnd(e);
      },
      { passive: true }
    );
  },
};
