export const ErrorHandlerMixin = {
  methods: {
    errorHandler (err) {
      const message = err?.response?.data?.message || err.message
      const code = err?.response?.data?.error_code || 500

      if(code === 422){
        this.$root.$emit('validation-error', message)
        return;
      }
      this.error = {
        code: code,
        message: this.$t(`errors.${message}`) || message
      }
    },
  }
}
