export const SurveyMetaMixin = {
  computed: {
    code () {
      return this.$route.params?.code
    },
    isLastPage () {
      return this.current === this.max - 1
    },
    showQuestionCode () {
      return this.survey?.show_question_code
    },
    showAnswerCode () {
      return this.survey?.show_answer_code
    },
    showResponseSummary () {
      return this.survey?.show_response_summary
    },
    translations () {
      return { ...this.survey?.translations[this.lang] }
    },
    max () {
      return this.survey?.page_ids?.length
    },
  }
}
