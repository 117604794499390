export const SurveyLayoutMixin = {
  data() {
    return {
      navWidth: {},
    };
  },
  methods: {
    syncWith(payload) {
      this.navWidth = {};
      this.navWidth[payload] = true;
    },
  },
};
